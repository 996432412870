import React from "react"
import { graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"

const AboutPage = ({ data }) => {
  const message = JSON.parse(data.allContentfulAboutPage.edges[0].node.bio.raw)
  const text = message.content[0].content[0].value
  return (
    <Layout>
      <SiteMetadata title="About" description="About Tara Segda" />

      <div className="bg-gray-100">
        <div className="container py-12 lg:pb-16">
          <h1 className="text-3xl text-center font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            About Me
          </h1>
          <div className="flex flex-wrap items-center sm:m-3 lg:m-8">
          <div className="w-full sm:py-4 md:w-1/2 xl:w-2/5 md:pr-6">
              <Img
                fluid={data.allContentfulAboutPage.edges[0].node.image.fluid}
                alt="Tara Segda"
                className="rounded-md shadow-md"
              />
            </div>
            <div className="w-full md:w-1/2 xl:w-3/5">
              <div className="bg-white shadow-md rounded-md p-4 text-gray-700">
                <p>{text}</p>
              </div>
            </div>
            
          </div>
          <div className="w-full content-center my-10">
            <h2 className="text-2xl text-center font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Resume
            </h2>
            <div className="flex w-full justify-center">
              <div className="my-4 w-5/6 md:w-4/5">
                <Img
                  fluid={data.allContentfulResume.edges[0].node.img.fluid}
                  alt="Resume"
                  className=" shadow-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query AboutQuery {
    allContentfulAboutPage {
      edges {
        node {
          bio {
            raw
          }
          image {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    allContentfulResume {
      edges {
        node {
          pdf {
            file {
              url
            }
          }
          img {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`
